.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
}

.board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;
  font-size: 20px;
  border-radius: 10px;
  border: 3px solid black;
  font-weight: bold;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.dimension {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 100px;
  height: 50px;
}

.score {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 5px;
  font-size: 20px;
  border-radius: 10px;
  border: 3px solid black;
  font-weight: bold;
}

.dimension > div {
  cursor: pointer;
}

.headerText {
  margin: 0;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}
